<template>
    <div>
        <div class="box">
            <CustomTable
                id_table="check_deposit_configuration"
                :items="filtredCheckConfig"
                :busy.sync="table_busy"
                primaryKey="checkdepositconfiguration_id"
                :hide_if_empty="true"
				:switch="true"
				:switchOn="$t('trigger.actif')"
				:switchOff="$t('trigger.inactif')"
				:callback_switch="UpdateTest"
            />
    
            <b-modal ref="modalCheckDepositConfig" hide-footer>
                <template v-slot:modal-title>

                    <template v-if="ajouter">
                        {{ $t("action.ajout_check_deposit_configuration") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("action.modifier_check_deposit_configuration") }}
                    </template>
                </template>

                <div v-if="!modal_loaded">
                    <LoadingSpinner class="col-12" />
                </div>

                <div v-if="modal_loaded">

                    <CheckDepositConfigurationAdd ref="checkDepositConfig" :checkdepositconfiguration_id="checkdepositconfiguration_id" :checks_config="checks_config" :processing.sync="processing" :ready.sync="ready" :accountingplan_id="local_accountingplan_id"></CheckDepositConfigurationAdd>

                    <div class="col-8 m-auto">
                        <b-button v-if="ready && checkdepositconfiguration_id" block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
                        <b-button v-else-if="ready" block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"
    import Accounting from "@/mixins/Accounting.js"
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: "Accounting",
        mixins: [Config, TableAction, Navigation, Accounting, Shutter],
        props: ['accountingplan_id', 'code_onboarding'],
        data () {
            return {
                checks_config: null,
                table_busy: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                checkdepositconfiguration_id: null,
                ajouter: true,
                modifier: false,

                events_tab: {
                    'TableAction::goToAddCheckDepositConfiguration': (params) => {
                        this.addOrUpdateCheckDepositConfiguration()
                    },
                    'TableAction::goToEditCheckDepositConfiguration': (params) => {
                        this.addOrUpdateCheckDepositConfiguration(params.checkdepositconfiguration_id)
                    },
                    'TableAction::goToDeleteCheckDepositConfiguration': (params) => {
                        this.deleteCheckDepositConfiguration(params.checkdepositconfiguration_id)
                    }
                }

                // config_table_hrefs: {
                //     'accountingplan_label': {
                //         routeUniqueName: 'accountingFiche',
                //         routeName: 'accountingFiche',
                //         params: {
                //             accountingplan_id: 'accountingplan_id'
                //         }
                //     },
                //     'tiers.tiers_rs': {
                //         routeUniqueName: 'tiersFiche',
                //         routeName: 'tiersFiche',
                //         params: {
                //             tiers_id: 'tiers.tiers_id'
                //         }
                //     }
                // },
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadCheckDepositConfigListe()
            },
            async loadCheckDepositConfigListe() {
                this.table_busy = true
                this.local_accountingplan_id = this.accountingplan_id ? this.accountingplan_id : this.$route.params.accountingplan_id
                const checks_config = await this.getAllCheckDepositConfigListe(this.local_accountingplan_id)
				this.checks_config = this.formatCheckConfigConfiguration(checks_config)
                this.table_busy = false
            },

			formatCheckConfigConfiguration(checks_config) {
				let checksConfigFormated = []

				if(!checks_config)
                {
                    return []
                }

				for (let i = 0; i < checks_config.length; i++) {
					const temp = checks_config[i]
					temp.id = temp.checkdepositconfiguration_id
					temp.switch = !temp.checkdepositconfiguration_inactive
					checksConfigFormated.push(temp);
				}

                return checksConfigFormated
			},

            addOrUpdateCheckDepositConfiguration(checkdepositconfiguration_id, val){
                this.modal_loaded = false
                this.ajouter = true
                this.modifier = false
                this.ready = false
                this.processing = false

                this.checkdepositconfiguration_id = null
                if(checkdepositconfiguration_id != undefined)
                {
                    this.ajouter = false
                    this.modifier = true
                    this.checkdepositconfiguration_id = checkdepositconfiguration_id
                }

                this.$refs['modalCheckDepositConfig'].show()

                this.ready = true
                this.modal_loaded = true
            },

			async UpdateTest(checkdepositconfiguration_id, val)
			{
				const checkdepositconfiguration = this.checks_config.find((cc) => (cc.checkdepositconfiguration_id === checkdepositconfiguration_id))
				checkdepositconfiguration.checkdepositconfiguration_inactive = !val
				await this.updateCreateCheckDepositConfig(checkdepositconfiguration, checkdepositconfiguration.checkdepositconfiguration_accountingplan.accountingplan_id);
			},

            async deleteCheckDepositConfiguration(checkdepositconfiguration_id)
            {
                let isOk = await this.deleteCheckDepositConfig(this.local_accountingplan_id, checkdepositconfiguration_id)
                if(isOk != null)
                {
                    this.loadCheckDepositConfigListe()
                }
            },

            async onSubmit(){
                const isOk = await this.$refs['checkDepositConfig'].checkForm()
                if(isOk){
                    this.$refs['modalCheckDepositConfig'].hide()
                    if(this.code_onboarding) {
                        this.ok()
                    }
                    else {
                        this.loadCheckDepositConfigListe()
                    }
                }
            }
        },
        computed: {
            filtredCheckConfig: function() {
                return this.checks_config
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            CheckDepositConfigurationAdd: () => import('@/components/Accounting/CheckDepositConfigurationAdd'),
        }
    }
</script>
